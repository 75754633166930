import React, { useState } from 'react'
import { Box, Columns, Button, Tag, Card, Heading } from 'react-bulma-components/dist';
import { invoiceStatus, Stage } from '../../utilities/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { dateToDateString } from '../../utilities/helper'
import { nextStageForScreen } from '../../utilities/Classes'



const ProductCard = (props) => {


    let [selected, setSelected] = useState(props.product.isSelected)

    const viewPressed = (prod) => {
        props.viewProductPressed(prod)
    }


    const productSelected = (prod) => {
        prod.isSelected = !prod.isSelected
        setSelected(!selected)
        props.productSelected(prod)  // just changed the isSelected of product
    }


    const productUpdated = () => {

    }


    // const pendingInvoiceBox = () => { return (
    //     <Box className='hoverShine noBreakWords generalCardBG'>
    //         <Columns className='is-mobile is-size-7'>
    //             <Columns.Column className=''>
    //                 <p className='has-text-grey has-text-weight-semibold  marginBottom5'> INVOICE </p>
    //                 <p className='maxWrap165 has-text-weight-semibold' >TW202003948-W2883</p><p>Tailorwise Standard Plan  </p><p>(Dec-2020 to Jan-2020) </p>
    //             </Columns.Column>
    //             <Columns.Column className=''>
    //                 <p className='has-text-grey  marginBottom5'> Due Date </p>
    //                 <p>31·Dec·20</p>
    //             </Columns.Column>
    //             <Columns.Column className=''>
    //                 <p className='has-text-grey  marginBottom5'> Amount </p>
    //                 <p>$21000</p>
    //             </Columns.Column>
    //             <Columns.Column className=''>
    //                 <p className='has-text-grey  marginBottom5'> Payment </p>
    //             </Columns.Column>
    //             <Columns.Column className=''>
    //                 <Button className='is-small is-outlined'> View </Button>
    //             </Columns.Column>
    //             <Columns.Column className=''>
    //                 <Button className='is-black is-fullwidth is-small is-outlined' > Pay Now </Button>
    //             </Columns.Column>
    //         </Columns>
    //     </Box>
    // )}


    const productBox = (product) => {
        return (
            <Box className='hoverShine noBreakWords generalCardBG prodCard' onClick={() => { viewPressed(product) }} style={{ cursor: 'pointer' }}>
                <p className='has-text-grey is-uppercase type55 marginBottom5 is-pulled-right '> {product.productId} </p>
                <p className='has-text-grey has-text-weight-semibold is-size-7 marginBottom5'> {product.type} </p>

                <div className='marginBottom10'>
                    {
                        (nextStageForScreen(props.screen) != null && props.selectable) ?

                            <div>
                                <div className="control">
                                    <label className="checkbox is-size-7">
                                        <input type="checkbox" className='checkbox has-text-grey  is-size-7 marginBottom10' onChange={() => { productSelected(product) }} checked={product.isSelected} />
                                    </label>
                                </div>



                                {/* <p className='has-text-grey  is-size-7 marginBottom10'> <input type="checkbox" onChange={() => { productSelected(product) }} checked={product.isSelected} /> &nbsp; <span className={product.stage === Stage.Delivered ? "has-text-success" : ""}>{product.stage}</span>  {product.retrial > 0 ? ('· Retrial ' + product.retrial) : null} </p> */}

                            </div>
                            :
                            <p className='has-text-grey  type50 marginBottom10 '> <span className={product.stage === Stage.Delivered ? "has-text-success" : ""}>{product.stage}</span> </p>

                    }
                </div>




                <Columns className='is-mobile is-size-7'>

                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Trial </p>
                        <p className='type55'>{product.trialDate ? dateToDateString(product.trialDate) : "__"} </p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Delivery </p>
                        <p className='type55'>{product.deliveryDate ? dateToDateString(product.deliveryDate) : "__"} </p>
                    </Columns.Column>

                    {/* <Columns.Column className=''>
                        <Button className='is-small is-black is-fullwidth is-outlined' onClick={() => { viewPressed(product) }}> View </Button>
                    </Columns.Column> */}
                </Columns>
            </Box >
        )
    }


    const activePlanCard = (product) => (
        <Card className='hoverShine noBreakWords generalCardBG prodCard '>
            <Card.Header className='has-background-light'>
                <Card.Header.Title className="title type50 ">
                    {
                        (nextStageForScreen(props.screen) != null && props.selectable) ?



                            <div>
                                <div className="control">
                                    <label className="checkbox is-size-7">
                                        <input type="checkbox" className='checkbox has-text-grey  is-size-7 marginBottom6' onChange={() => { productSelected(product) }} checked={product.isSelected} />
                                        <span className="type50"> &nbsp; {product.productId} &nbsp; · &nbsp;  <span className={product.stage === Stage.Delivered ? "has-text-success type40" : "has-text-grey type40"}>{product.stage}</span>  </span> 
                                    </label>
                                </div>


                                {/* <p className='has-text-grey  is-size-7 marginBottom10'> <input type="checkbox" onChange={() => { productSelected(product) }} checked={product.isSelected} /> &nbsp; <span className={product.stage === Stage.Delivered ? "has-text-success" : ""}>{product.stage}</span>  {product.retrial > 0 ? ('· Retrial ' + product.retrial) : null} </p> */}



                                {/* <p className='has-text-grey  is-size-7 '> <input type="checkbox" onChange={() => { productSelected(product) }} checked={product.isSelected} /> &nbsp;</p> */}
                            </div>

                            :
                            <div>
                              <span className="type50">  {product.productId} &nbsp; · &nbsp;  <span className={product.stage === Stage.Delivered ? "has-text-success type40" : " has-text-grey type40"}>{product.stage}</span></span> 
                            </div>

                    }

                </Card.Header.Title>
            </Card.Header>
            <Card.Content onClick={() => { viewPressed(product) }} style={{ cursor: 'pointer' }}>
                <Heading size={6}> {product.type} </Heading>
                <Columns className='is-mobile is-size-7 '>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Trial </p>
                        <p className=''>{product.trialDate ? dateToDateString(product.trialDate) : "__"} </p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Delivery </p>
                        <p className=''>{product.deliveryDate ? dateToDateString(product.deliveryDate) : "__"}</p>
                    </Columns.Column>
                </Columns>
            </Card.Content>
            <Card.Footer onClick={() => { viewPressed(product) }} style={{ cursor: 'pointer' }}>
                <p className='type55 has-text-left has-text-grey has-text-weight-medium pad6'>  {product.retrial > 0 ? ('· Fit ' + (product.retrial + 1)) : null} </p>

            </Card.Footer>
        </Card >
    )

    const productBoxOld = (product) => {

        // type, groupType, id, stage, saleType, styleNo, trial, delivery, stocks, pics, remarks

        return (
            <Box key={product.objectId} className='hoverShine noBreakWords  generalCardBG'>
                {/* <Button className="type74  has-text-grey-light is-pulled-right no-decoration is-text is-small"> <FontAwesomeIcon className='' icon={faEllipsisH} /></Button> */}

                {
                    (nextStageForScreen(props.screen) != null) ?

                        <label class="checkbox">
                            <p className='has-text-dark-grey has-text-weight-semibold is-size-7 '> <input type="checkbox" onChange={() => { productSelected(product) }} checked={product.isSelected} />  &nbsp; {product.productId} · {product.type} · <span className=' type50'>{product.stage} </span> </p>
                        </label>

                        : null
                }



                <Columns className='is-mobile is-size-7 marginTop5'>
                    {/* <Columns.Column className=''>
                        <p className='has-text-grey type45 is-uppercase  marginBottom5'> Style </p>
                        <p>
                            {product.styleNo ?? ''}
                        </p>
                    </Columns.Column> */}
                    <Columns.Column className=''>
                        <p className='has-text-grey type45  is-uppercase marginBottom5'> Trial </p>
                        <p>{product.trialDate ? dateToDateString(product.trialDate) : null} </p>


                        {/* <p>D:{delivery}</p> */}
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey type45 is-uppercase marginBottom5'> Delivery </p>
                        {/* <p>T:{trial}</p> */}
                        <p>{product.deliveryDate ? dateToDateString(product.deliveryDate) : null} </p>

                    </Columns.Column>
                    <Columns.Column className=''>
                        <Button className='is-small is-fullwidth is-outlined' onClick={() => { viewPressed(product) }}> View </Button>
                    </Columns.Column>

                </Columns>
            </Box>
        )
    }


    // const productBox = (product) => {

    //     // type, groupType, id, stage, saleType, styleNo, trial, delivery, stocks, pics, remarks

    //     return (
    //         <Box key={product.objectId} className='hoverShine noBreakWords generalCardBG'>
    //             <Button className="type74  has-text-grey-light is-pulled-right no-decoration is-text is-small"> <FontAwesomeIcon className='' icon={faEllipsisH} /></Button>
    //             <label class="checkbox">
    //                 <p className='has-text-dark-grey has-text-weight-semibold is-size-7 '> <input type="checkbox" onChange={() => { productSelected(product) }} checked={ product.isSelected } />  &nbsp; {product.productId} · {product.type} · <span className=' type50'>{product.stage} </span> </p>
    //             </label>

    //             <Columns className='is-mobile is-size-7 marginTop5'>
    //                 {/* <Columns.Column className=''>
    //                     <p className='has-text-grey type45 is-uppercase  marginBottom5'> Style </p>
    //                     <p>
    //                         {product.styleNo ?? ''}
    //                     </p>
    //                 </Columns.Column> */}
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey type45  is-uppercase marginBottom5'> Trial </p>
    //                     <p>{ product.trialDate ?  dateToDateString(product.trialDate) : null } </p>


    //                     {/* <p>D:{delivery}</p> */}
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey type45 is-uppercase marginBottom5'> Delivery </p>
    //                     {/* <p>T:{trial}</p> */}
    //                     <p>{ product.deliveryDate ?  dateToDateString(product.deliveryDate) : null } </p>

    //                 </Columns.Column>
    //                 <Columns.Column className='is-narrow'>
    //                     <p className='has-text-grey type45 is-uppercase marginBottom5'> Measr. </p>
    //                     <p>{product.measurementsCount ?? null}</p>
    //                     {/* <p><Tag color="light" >{pics} Pics</Tag> </p> */}
    //                     {/* <p><Tag color="light" >{remarks} Remarks</Tag> </p> */}
    //                 </Columns.Column>
    //                 <Columns.Column className='is-narrow'>
    //                     <p className='has-text-grey type45 is-uppercase  marginBottom5'> Pics </p>
    //                     {/* <p><Tag color="light" >{stocks} Stocks</Tag> </p> */}
    //                     <p>{product.picsCount ?? null} </p>
    //                     {/* <p><Tag color="light" >{remarks} Remarks</Tag> </p> */}
    //                 </Columns.Column>
    //                 <Columns.Column className='is-narrow'>
    //                     <p className='has-text-grey type45 is-uppercase  marginBottom5'> Remarks </p>
    //                     {/* <p><Tag color="light" >{stocks} Stocks</Tag> </p> */}
    //                     {/* <p><Tag color="light" >{pics} Pics</Tag> </p> */}
    //                     <p>{product.remarksCount ?? null}</p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <Button className='is-small is-fullwidth is-outlined' onClick={ () => { viewPressed(product) } }> View </Button>
    //                 </Columns.Column>

    //             </Columns>
    //         </Box>
    //     )
    // }



    // const productBox = (type, groupType, id, stage, saleType, styleNo, trial, delivery, stocks, pics, remarks) => {

    //     return (
    //         <Box key={id} className='hoverShine noBreakWords generalCardBG'>
    //             <Columns className='is-mobile is-size-7'>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey has-text-weight-semibold  marginBottom5'> PRODUCT </p>
    //                     <p className='maxWrap165 has-text-weight-semibold'>{type} </p> <p> ({id}) </p><p className='type50'>({stage}) </p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Style </p>
    //                     <p>{styleNo}</p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Trial </p>
    //                     <p>{trial}</p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Delivery </p>
    //                     <p>{delivery}</p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Stocks </p>
    //                     <Tag color="light" >{stocks}</Tag>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Pics </p>
    //                     <Tag color="light" >{pics}</Tag>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Stocks </p>
    //                     <Tag color="light" >{remarks}</Tag>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <Button className='is-small is-fullwidth is-outlined' onClick={viewPressed}> View </Button>
    //                 </Columns.Column>
    //             </Columns>
    //         </Box>
    //     )
    // }

    // const paidInvoiceBox = (id, name, description, dueDate, amount, status, paymentId, paymentDate) => {

    //     return (
    //         <Box className='hoverShine noBreakWords generalCardBG'>
    //             <Columns className='is-mobile is-size-7'>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey has-text-weight-semibold  marginBottom5'> INVOICE </p>
    //                     <p className='maxWrap165 has-text-weight-semibold'>{id} </p> <p>{name}  </p><p>({description}) </p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Due Date </p>
    //                     <p>{dueDate}</p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Amount </p>
    //                     <p>₹{amount}</p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Payment </p>
    //                     <Tag color="light" >{status}</Tag>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <p className='has-text-grey  marginBottom5'> Payment Id </p>
    //                     <p>{paymentId}</p>
    //                     <p>({paymentDate})</p>
    //                 </Columns.Column>
    //                 <Columns.Column className=''>
    //                     <Button className='is-small is-fullwidth is-outlined' onClick={viewPressed}> View </Button>
    //                 </Columns.Column>
    //             </Columns>
    //         </Box>
    //     )
    // }





    let prodBox = () => {
        return activePlanCard(props.product)
    }




    return (

        prodBox()
    )
}

export default ProductCard