import {  dateToDateString } from './helper'
import { ClientAge, ClientCategory, Profession, SpendRange, Status, Stage, Purpose, FromStoreSection, SaleType } from './enums'
import {  ProductType } from '../ClientInfo/clientInfo'




export class DateRange {
    // var from : Date?
    // var to : Date?

    constructor(from, to) {
        this.from = from
        this.to = to
    }
}


export class CountRange {
    // var from : Int?
    // var to : Int?

    constructor(from, to) {
        this.from = from
        this.to = to
    }
}

export class QuantRange {
    // var from : Double?
    // var to : Double?

    constructor(from, to) {
        this.from = from
        this.to = to
    }
}



export const emptyCustomerFilter = () => {
    return new CustomerFilter(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
}



export class CustomerFilter {


    constructor(name, phone, email, country, age, category, profession, spendRange, createdOn, birthday, specialOccasion, numberOfOrders, birthdayMatching, occasionMatching, registeredAt, addedByUser, lastOrderedAfter, customId) {

        this.name = name
        this.phone = phone
        this.email = email
        this.country = country
        this.age = age
        this.category = category
        this.profession = profession
        this.spendRange = spendRange
        this.createdOn = createdOn
        this.birthday = birthday
        this.specialOccasion = specialOccasion
        this.numberOfOrders = numberOfOrders
        this.birthdayMatching = birthdayMatching
        this.occasionMatching = occasionMatching
        this.registeredAt = registeredAt
        this.lastOrderedAfter = lastOrderedAfter
        this.customId = customId
        this.addedByUser = addedByUser
        this.filterCount = 0
        this.active = false

    }

    static init = () => {
        let cf = new CustomerFilter(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        return cf
    }

    static copyFrom = (obj) => {
        let ncf = CustomerFilter.init()
        ncf.name = obj.name
        ncf.phone = obj.phone
        ncf.email = obj.email
        ncf.country = obj.country
        ncf.age = obj.age
        ncf.category = obj.category
        ncf.profession = obj.profession
        ncf.spendRange = obj.spendRange
        ncf.createdOn = obj.createdOn
        ncf.birthday = obj.birthday
        ncf.specialOccasion = obj.specialOccasion
        ncf.numberOfOrders = obj.numberOfOrders
        ncf.birthdayMatching = obj.birthdayMatching
        ncf.occasionMatching = obj.occasionMatching
        ncf.registeredAt = obj.registeredAt
        ncf.addedByUser = obj.addedByUser
        ncf.customId = obj.customId

        ncf.lastOrderedAfter = obj.lastOrderedAfter
        ncf.filterCount = obj.filterCount
        ncf.active = obj.active
        return ncf
    }


    isActive() {
        let result = this.decideActiveAndCount()
        this.filterCount = result.filterCount
        this.active = result.active
        return result.active
    }

    decideActiveAndCount() {
        var filterCount = 0
        var isA = false



        if (this.registeredAt != null) {
            isA = true
            filterCount += 1
        }

        if (this.addedByUser != null) {
            isA = true
            filterCount += 1
        }

        if (this.lastOrderedAfter != null) {
            isA = true
            filterCount += 1
        }


        if (this.name != null) {
            if (this.name.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.customId != null) {
            if (this.customId.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.phone != null) {
            if (this.phone.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.email != null) {
            if (this.email.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.country != null) {
            if (this.country.length > 0) {
                isA = true
                filterCount += 1
            }
        }


        if (this.age != null) {
            if (this.age !== ClientAge.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.category != null) {
            if (this.category !== ClientCategory.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.profession != null) {
            if (this.profession !== Profession.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.spendRange != null) {
            if (this.spendRange !== SpendRange.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.numberOfOrders != null) {
            if (this.numberOfOrders.from != null || this.numberOfOrders.to != null) {
                isA = true
                if (this.numberOfOrders.from != null) {
                    filterCount += 1
                }
                if (this.numberOfOrders.to != null) {
                    filterCount += 1
                }
            }
        }



        if (this.createdOn != null) {
            if (this.createdOn.from != null || this.createdOn.to != null) {
                isA = true
                if (this.createdOn.from != null) {
                    filterCount += 1
                }
                if (this.createdOn.to != null) {
                    filterCount += 1
                }
            }
        }




        if (this.birthdayMatching != null) {
            isA = true
            filterCount += 1
        }

        if (this.occasionMatching != null) {
            isA = true
            filterCount += 1
        }



        if (this.birthday != null) {
            if (this.birthday.from != null || this.birthday.to != null) {
                isA = true
                if (this.birthday.from != null) {
                    filterCount += 1
                }
                if (this.birthday.to != null) {
                    filterCount += 1
                }
            }
        }

        if (this.specialOccasion != null) {
            if (this.specialOccasion.from != null || this.specialOccasion.to != null) {
                isA = true
                if (this.specialOccasion.from != null) {
                    filterCount += 1
                }
                if (this.specialOccasion.to != null) {
                    filterCount += 1
                }
            }
        }

        return { active: isA, filterCount: filterCount }
    }

    description() {

        if (this.active === false) {
            return ''
        }

        let startState1 = "Client "
        let startState2 = "Clients"
        var desc = startState1


        if (this.name != null) {
            if (this.name.length > 0) {
                desc += `named ${this.name}`
            }
        }

        if (this.customId != null) {
            if (this.customId.length > 0) {
                desc += `with CustomId containing ${this.customId}`
            }
        }

        if (this.phone != null) {
            if (this.phone.length > 0) {
                if (desc !== startState1) {
                    if (desc.length > 0) {
                        desc += ". "
                    }
                }
                desc += `With Phone ${this.phone}`
            }
        }

        if (this.email != null) {
            if (this.email.length > 0) {
                if (this.email.length > 0) {
                    desc += ". "
                }
                desc += `With Email ${this.email}`
            }
        }


        if (desc === startState1) {
            desc = startState2
        }


        if (this.country != null) {
            if (this.country.length > 0) {
                desc += ". "
                desc += `From ${this.country}`
            }
        }


        if (this.age != null) {
            if (this.age !== ClientAge.None) {
                desc += ". "
                desc += `In ${this.age} Age`
            }
        }


        if (this.birthdayMatching != null) {

            // let comps = stringComps(this.birthdayMatching, 2)
            // let day = comps[0]
            // let month = comps[1]

            desc += ". "
            desc += `With Birthday on ${dateToDateString(this.birthdayMatching)}`
        }

        if (this.occasionMatching != null) {
            // let comps = stringComps(this.occasionMatching, 2)
            // let day = comps[0]
            // let month = comps[1]

            desc += ". "
            desc += `With Occasion on ${dateToDateString(this.occasionMatching)}`
        }


        if (this.category != null) {
            if (this.category !== ClientCategory.None) {
                desc += ". "
                desc += `In ${this.category} category`
            }
        }

        if (this.profession != null) {
            if (this.profession !== Profession.None) {
                desc += ". "
                desc += `${this.profession} by profession`
            }
        }

        if (this.spendRange != null) {
            if (this.spendRange !== SpendRange.None) {
                desc += ". "
                desc += `With Spend Range ${this.spendRange}`
            }
        }

        if (this.createdOn != null) {
            if (this.createdOn.from != null || this.createdOn.to != null) {
                if (desc.length > 0) {
                    desc += ". "
                }

                if (this.createdOn.to != null && this.createdOn.from != null) {

                    if (this.createdOn.to === this.createdOn.from) {
                        desc += `Created on ${dateToDateString(this.createdOn.from)}`
                    } else {
                        desc += `Created between ${dateToDateString(this.createdOn.from)}  &  ${dateToDateString(this.createdOn.to)}`
                    }


                } else {
                    if (this.createdOn.from != null) {
                        desc += `Created after ${dateToDateString(this.createdOn.from)}`
                    }

                    if (this.createdOn.to != null) {
                        desc += `Created before ${dateToDateString(this.createdOn.to)}`
                    }

                }

            }
        }




        if (this.birthday != null) {

            if (this.birthday.from != null || this.birthday.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                if (this.birthday.to != null && this.birthday.from != null) {

                    if (this.birthday.to === this.birthday.from) {
                        desc += `Birthday on ${dateToDateString(this.birthday.from)}`
                    } else {
                        desc += `Birthday between ${dateToDateString(this.birthday.from)}  &  ${dateToDateString(this.birthday.to)}`
                    }


                } else {
                    if (this.birthday.from != null) {
                        desc += `Birthday after ${dateToDateString(this.birthday.from)}`
                    }

                    if (this.birthday.to != null) {
                        desc += `Birthday before ${dateToDateString(this.birthday.to)}`
                    }

                }

            }

        }



        if (this.specialOccasion != null) {
            if (this.specialOccasion.from != null || this.specialOccasion.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                if (this.specialOccasion.to != null && this.specialOccasion.from != null) {

                    if (this.specialOccasion.to === this.specialOccasion.from) {
                        desc += `Occaion on ${dateToDateString(this.specialOccasion.from)}`

                    } else {
                        desc += `Occaion between ${dateToDateString(this.specialOccasion.from)}  &  ${dateToDateString(this.specialOccasion.to)}`

                    }


                } else {
                    if (this.specialOccasion.from != null) {
                        desc += `Occaion after ${dateToDateString(this.specialOccasion.from)}`
                    }

                    if (this.specialOccasion.to != null) {
                        desc += `Occaion before ${dateToDateString(this.specialOccasion.to)}`
                    }

                }

            }
        }


        if (this.numberOfOrders != null) {
            if (this.numberOfOrders.from != null || this.numberOfOrders.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                if (this.numberOfOrders.to != null && this.numberOfOrders.from != null) {

                    if (this.numberOfOrders.to === this.numberOfOrders.from) {
                        desc += `${this.numberOfOrders.from} Number of Orders`

                    } else {
                        desc += `Number of Orders between ${this.numberOfOrders.from}  &  ${this.numberOfOrders.to}`
                    }

                } else {
                    if (this.numberOfOrders.from != null) {
                        desc += `Number of Orders more than or equal to ${this.numberOfOrders.from}`
                    }

                    if (this.numberOfOrders.to != null) {
                        desc += `Number of Orders less than or equal to ${this.numberOfOrders.to}`
                    }

                }

            }

        }



        if (this.lastOrderedAfter != null) {
            desc += `. Who ordered on or after ${this.lastOrderedAfter}`
        }

        if (desc === startState1 || desc === startState2) {
            desc = ""
        } else {
            desc += "."
        }

        var registeredAtStr = ""

        if (this.registeredAt != null) {
            registeredAtStr = `At ${this.registeredAt.name}.`
        }

        if (this.addedByUser != null) {
            desc = `Added by ${this.addedByUser.name}. ${desc}`
        }


        desc = `${registeredAtStr} ${desc}`

        return desc
    }

}

export class OrderFilter {




    constructor(
        customer, customerName, customerPhone, customerEmail, orderedAt, orderNo, posNo, trialDate, deliveryDate, bookingDate, numberOfProducts, isCancelled, cancelledBy,
        addedByUser, status, productStage, productType, productPurpose, productTrialDate, productDeliveryDate, productOrderedAt, productMakeAt) {

        this.customer = customer
        this.customerName = customerName
        this.customerPhone = customerPhone
        this.customerEmail = customerEmail
        this.orderedAt = orderedAt
        this.orderNo = orderNo
        this.posNo = posNo
        this.trialDate = trialDate
        this.deliveryDate = deliveryDate
        this.bookingDate = bookingDate
        this.numberOfProducts = numberOfProducts
        this.isCancelled = isCancelled
        this.cancelledBy = cancelledBy
        this.addedByUser = addedByUser
        this.status = status
        this.productStage = productStage
        this.productNotStages = null
        this.findInFactoryOnly = false
        this.findInVendorsOnly = false
        this.productType = productType
        this.productPurpose = productPurpose
        this.productTrialDate = productTrialDate
        this.productDeliveryDate = productDeliveryDate
        this.productOrderedAt = productOrderedAt
        this.productMakeAt = productMakeAt
        this.product_cp_AssignedToInfo = null
        this.filterCount = 0
        this.active = false
    }

    static init = () => {
        let of = new OrderFilter(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        return of
    }

    static copyFrom = (obj) => {
        let nof = OrderFilter.init()
        nof.customer = obj.customer
        nof.customerName = obj.customerName
        nof.customerPhone = obj.customerPhone
        nof.customerEmail = obj.customerEmail
        nof.orderedAt = obj.orderedAt
        nof.orderNo = obj.orderNo
        nof.posNo = obj.posNo
        nof.trialDate = obj.trialDate
        nof.deliveryDate = obj.deliveryDate
        nof.bookingDate = obj.bookingDate
        nof.numberOfProducts = obj.numberOfProducts
        nof.isCancelled = obj.isCancelled
        nof.cancelledBy = obj.cancelledBy
        nof.addedByUser = obj.addedByUser
        nof.status = obj.status
        nof.productStage = obj.productStage
        nof.productNotStages = obj.productNotStages
        nof.findInFactoryOnly = obj.findInFactoryOnly
        nof.findInVendorsOnly = obj.findInVendorsOnly
        nof.productType = obj.productType
        nof.productPurpose = obj.productPurpose
        nof.productTrialDate = obj.productTrialDate
        nof.productDeliveryDate = obj.productDeliveryDate
        nof.productOrderedAt = obj.productOrderedAt
        nof.productMakeAt = obj.productMakeAt
        nof.product_cp_AssignedToInfo = obj.product_cp_AssignedToInfo
        nof.filterCount = obj.filterCount
        nof.active = obj.active
        return nof
    }

    isActive() {
        let result = this.decideActiveAndCount()
        this.filterCount = result.filterCount
        this.active = result.active
        return result.active
    }

    decideActiveAndCount() {
        var filterCount = 0
        var isA = false

        if (this.orderNo != null) {
            if (this.orderNo.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.posNo != null) {
            if (this.posNo.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.customer != null) {
            isA = true
            filterCount += 1
        }

        if (this.customerPhone != null) {
            isA = true
            filterCount += 1
        }

        if (this.customerName != null) {
            isA = true
            filterCount += 1
        }

        if (this.customerEmail != null) {
            isA = true
            filterCount += 1
        }

        if (this.orderedAt != null) {
            isA = true
            filterCount += 1
        }


        if (this.status != null) {
            if (this.status !== Status.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.productStage != null) {
            if (this.productStage !== Stage.None) {
                isA = true
                filterCount += 1
            }
        }
        
        if (this.productNotStages != null) {
            if (this.productNotStages.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.findInFactoryOnly === true) {
            isA = true
            filterCount += 1
        }

        if (this.findInVendorsOnly === true) {
            isA = true
            filterCount += 1
        }

        if (this.productType != null) {
            if (this.productType !== ProductType.None){
                isA = true
                filterCount += 1
            }

        }


        if (this.trialDate != null) {
            if (this.trialDate.from != null || this.trialDate.to != null) {
                isA = true
                if (this.trialDate.from != null) {
                    filterCount += 1
                }
                if (this.trialDate.to != null) {
                    filterCount += 1
                }
            }
        }


        if (this.deliveryDate != null) {
            if (this.deliveryDate.from != null || this.deliveryDate.to != null) {
                isA = true
                if (this.deliveryDate.from != null) {
                    filterCount += 1
                }
                if (this.deliveryDate.to != null) {
                    filterCount += 1
                }
            }
        }


        if (this.bookingDate != null) {
            if (this.bookingDate.from != null || this.bookingDate.to != null) {
                isA = true
                if (this.bookingDate.from != null) {
                    filterCount += 1
                }
                if (this.bookingDate.to != null) {
                    filterCount += 1
                }
            }
        }


        if (this.numberOfProducts != null) {
            if (this.numberOfProducts.from != null || this.numberOfProducts.to != null) {
                isA = true
                if (this.numberOfProducts.from != null) {
                    filterCount += 1
                }
                if (this.numberOfProducts.to != null) {
                    filterCount += 1
                }
            }
        }


        if (this.productTrialDate != null) {
            if (this.productTrialDate.from != null || this.productTrialDate.to != null) {
                isA = true
                if (this.productTrialDate.from != null) {
                    filterCount += 1
                }
                if (this.productTrialDate.to != null) {
                    filterCount += 1
                }
            }
        }


        if (this.productDeliveryDate != null) {
            if (this.productDeliveryDate.from != null || this.productDeliveryDate.to != null) {
                isA = true
                if (this.productDeliveryDate.from != null) {
                    filterCount += 1
                }
                if (this.productDeliveryDate.to != null) {
                    filterCount += 1
                }
            }
        }



        if (this.productOrderedAt != null) {
            isA = true
            filterCount += 1
        }

        if (this.productMakeAt != null) {
            isA = true
            filterCount += 1
        }

        if (this.product_cp_AssignedToInfo != null){
            isA = true
            filterCount += 1
        }


        if (this.isCancelled != null) {
            isA = true
            filterCount += 1
        }

        if (this.cancelledBy != null) {
            isA = true
            filterCount += 1
        }

        if (this.addedByUser != null) {
            isA = true
            filterCount += 1
        }



        return { active: isA, filterCount: filterCount }
    }


    description() {

        //            var customerName : String? = null
        //            var customerPhone : String? = null
        //            var customerEmail : String? = null
        //
        //
        //            var orderNo : String? = null
        //            var trialDate : DateRange = DateRange.init(from: null, to: null)
        //            var bookingDate : DateRange = DateRange.init(from: null, to: null)
        //
        //            var status : Status? = null
        //            var productStage : Stage? = null
        //            var productType : ProductType? = null
        //            var productTrialDate : DateRange = DateRange.init(from: null, to: null)
        //            var productDeliveryDate : DateRange = DateRange.init(from: null, to: null)



        if (this.active === false) {
            return ''
        }


        let startState1 = "Order "
        let startState2 = "Orders"
        var desc = startState1

        if (this.orderNo != null) {
            if (this.orderNo.length > 0) {
                desc += `with no ${this.orderNo}`
            }
        }

        if (desc === startState1) {
            desc = startState2
        }


        if (this.orderNo != null) {
            if (this.orderNo.length > 0) {
                desc += `. With POS No. ${this.orderNo}`
            }
        }


        if (this.customerName != null) {
            if (this.customerName.length > 0) {
                desc += `of Customer with name ${this.customerName}`
            }
        }

        if (this.customerPhone != null) {
            if (this.customerPhone) {
                desc += `of Customer with phone ${this.customerPhone}`
            }
        }

        if (this.customerEmail != null) {
            if (this.customerEmail.length > 0) {
                desc += `of Customer with email ${this.customerEmail}`
            }
        }

        if (this.status != null) {
            if (this.status !== Status.None) {
                desc += ". "
                desc += `That are ${this.status}`
            }
        }


        if (this.productType != null) {
            if (this.productType !== ProductType.None) {
                desc += ". "
                desc += `With Products of type ${this.productType}`
            }
        }

        if (this.productStage != null) {
            if (this.productStage !== Stage.None) {
                desc += ". "
                desc += `With Products that are ${this.productStage}`
            }
        }

        if (this.productNotStages != null) {
            let vals = this.productNotStages.map( (thisNS) => { return thisNS })
            desc += ". "
            desc += `With Products that are NOT ${vals.join(", ")}`
        }


        if (this.findInFactoryOnly === true) {
            desc += ". "
            desc += `Which are at Factory.`
        }

        if (this.findInVendorsOnly === true) {
            desc += ". "
            desc += `Which are at the Vendor.`
        }


        if (this.productPurpose != null) {
            if (this.productPurpose !== Purpose.None) {
                desc += ". "
                desc += `With Products having purpose ${this.purpose}`
            }
        }

        if (this.productOrderedAt != null) {
            desc += ". "
            desc += `Ordered at ${this.productOrderedAt}`
        }

        if (this.productMakeAt != null) {
            desc += ". "
            desc += `Made at ${this.productMakeAt}`
        }

        

        if (this.product_cp_AssignedToInfo != null) {
            desc += ". "
            desc += `Work assignedTo ${this.product_cp_AssignedToInfo}`
        }

        if (this.bookingDate != null) {
            if (this.bookingDate.from != null || this.bookingDate.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                if (this.bookingDate.to != null && this.bookingDate.from != null) {
                    if (this.bookingDate.to === this.bookingDate.from) {
                        desc += `Booked on ${dateToDateString(this.bookingDate.from)}`
                    } else {
                        desc += `Booked between ${dateToDateString(this.bookingDate.from)}  &  ${dateToDateString(this.bookingDate.to)}`
                    }
                } else {
                    if (this.bookingDate.from != null) {
                        desc += `Booked after ${dateToDateString(this.bookingDate.from)}`
                    }

                    if (this.bookingDate.to != null) {
                        desc += `Booked before ${dateToDateString(this.bookingDate.to)}`
                    }
                }
            }
        }


        if (this.trialDate != null) {
            if (this.trialDate.from != null || this.trialDate.to != null) {
                if (desc.length > 0) {
                    desc += ". "
                }

                if (this.trialDate.to != null && this.trialDate.from != null) {
                    if (this.trialDate.to === this.trialDate.from) {
                        desc += `Order Trial Date on ${dateToDateString(this.trialDate.from)}`
                    } else {
                        desc += `Order Trial Date between ${dateToDateString(this.trialDate.from)}  &  ${dateToDateString(this.trialDate.to)}`
                    }
                } else {
                    if (this.trialDate.from != null) {
                        desc += `Order Trial Date after ${dateToDateString(this.trialDate.from)}`
                    }
                    if (this.trialDate.to != null) {
                        desc += `Order Trial Date before ${dateToDateString(this.trialDate.to)}`
                    }
                }
            }
        }



        if (this.numberOfProducts != null) {
            if (this.numberOfProducts.from != null || this.numberOfProducts.to != null) {
                if (desc.length > 0) {
                    desc += ". With"
                }
                if (this.numberOfProducts.to != null && this.numberOfProducts.from != null) {
                    if (this.numberOfProducts.to === this.numberOfProducts.from) {
                        desc += ` ${this.numberOfProducts.from} number Of Products`
                    } else {
                        desc += ` number Of Products between ${this.numberOfProducts.from}  &  ${this.numberOfProducts.to}`
                    }

                } else {
                    if (this.numberOfProducts.from != null) {
                        desc += ` number Of Products more than or equal to  ${this.numberOfProducts.from}`
                    }
                    if (this.numberOfProducts.to != null) {
                        desc += ` number Of Products less than or equal to ${this.numberOfProducts.to}`
                    }
                }
            }
        }




        if (this.productTrialDate != null) {
            if (this.productTrialDate.from != null || this.productTrialDate.to != null) {
                if (desc.length > 0) {
                    desc += ". "
                }
                if (this.productTrialDate.to != null && this.productTrialDate.from != null) {
                    if (this.productTrialDate.to === this.productTrialDate.from) {
                        desc += `With Products having Trial on ${dateToDateString(this.productTrialDate.from)}`
                    } else {
                        desc += `With Products having Trial between ${dateToDateString(this.productTrialDate.from)}  &  ${dateToDateString(this.productTrialDate.to)}`
                    }
                } else {
                    if (this.productTrialDate.from != null) {
                        desc += `With Products having Trial after ${dateToDateString(this.productTrialDate.from)}`
                    }

                    if (this.productTrialDate.to != null) {
                        desc += `With Products having Trial before ${dateToDateString(this.productTrialDate.to)}`
                    }
                }
            }
        }




        if (this.productDeliveryDate != null) {
            if (this.productDeliveryDate.from != null || this.productDeliveryDate.to != null) {
                if (desc.length > 0) {
                    desc += ". "
                }
                if (this.productDeliveryDate.to != null && this.productDeliveryDate.from != null) {
                    if (this.productDeliveryDate.to === this.productDeliveryDate.from) {
                        desc += `With Products having Delivery on ${dateToDateString(this.productDeliveryDate.from)}`
                    } else {
                        desc += `With Products having Delivery between ${dateToDateString(this.productDeliveryDate.from)}  &  ${dateToDateString(this.productDeliveryDate.to)}`
                    }

                } else {
                    if (this.productDeliveryDate.from != null) {
                        desc += `With Products having Delivery after ${dateToDateString(this.productDeliveryDate.from)}`
                    }
                    if (this.productDeliveryDate.to != null) {
                        desc += `With Products having Delivery before ${dateToDateString(this.productDeliveryDate.to)}`
                    }
                }
            }
        }




        if (desc === startState1 || desc === startState2) {
            desc = ""
        } else {
            desc += "."
        }

        if (this.isCancelled != null) {
            if (this.isCancelled === true) {
                desc = `Orders that are cancelled. ${desc}`
            }
        }

        if (this.cancelledBy != null) {
            desc = `Orders that are cancelled by ${this.cancelledBy.name}. ${desc}`
        }

        if (this.addedByUser != null) {
            desc = `Orders that are added by ${this.addedByUser.name}. ${desc}`
        }


        if (this.orderedAt != null) {
            desc = `At ${this.orderedAt.name}. ${desc}`
        }


        return desc
    }


}

export class ProductFilter {
    //    var challan: Challan? = null

    constructor(
        productId, objectId, type, stage, status, purpose, fromStoreSection, saleType, styleNo, challanNo, orderedAt, makeAt, trialDate, deliveryDate, retrial, finishing, addedByUser, assignTo, isCancelled, cancelledBy, usedVendorViaFactory
    ) {
        this.productId = productId
        this.objectId = objectId
        this.type = type
        this.stage = stage
        this.stages = null
        this.status = status
        this.purpose = purpose
        this.fromStoreSection = fromStoreSection
        this.saleType = saleType
        this.styleNo = styleNo
        this.challanNo = challanNo
        this.orderedAt = orderedAt
        this.makeAt = makeAt
        this.trialDate = trialDate
        this.deliveryDate = deliveryDate
        this.retrial = retrial
        this.finishing = finishing
        this.addedByUser = addedByUser
        this.vendor = null
        this.assignTo = assignTo
        this.isCancelled = isCancelled
        this.cancelledBy = cancelledBy
        this.usedVendorViaFactory = usedVendorViaFactory
        this.cp_assignedToInfo = null
        this.filterCount = 0
        this.active = false
    }

    static init = () => {
        let pf = new ProductFilter(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)
        return pf
    }

    static copyFrom = (obj) => {
        let npf = ProductFilter.init()
        npf.productId = obj.productId
        npf.objectId = obj.objectId
        npf.type = obj.type
        npf.stage = obj.stage
        npf.stages = obj.stages
        npf.status = obj.status
        npf.purpose = obj.purpose
        npf.fromStoreSection = obj.fromStoreSection
        npf.saleType = obj.saleType
        npf.styleNo = obj.styleNo
        npf.challanNo = obj.challanNo
        npf.orderedAt = obj.orderedAt
        npf.makeAt = obj.makeAt
        npf.trialDate = obj.trialDate
        npf.deliveryDate = obj.deliveryDate
        npf.retrial = obj.retrial
        npf.finishing = obj.finishing
        npf.addedByUser = obj.addedByUser
        npf.vendor = obj.vendor
        npf.assignTo = obj.assignTo
        npf.isCancelled = obj.isCancelled
        npf.cancelledBy = obj.cancelledBy
        npf.usedVendorViaFactory = obj.usedVendorViaFactory
        npf.cp_assignedToInfo = obj.cp_assignedToInfo
        npf.filterCount = obj.filterCount
        npf.active = obj.active
        return npf
    }

    isActive() {
        let result = this.decideActiveAndCount()
        this.filterCount = result.filterCount
        this.active = result.active
        return result.active
    }

    decideActiveAndCount() {
        var filterCount = 0
        var isA = false


        if (this.productId != null) {
            if (this.productId.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.objectId != null) {
            isA = true
            filterCount += 1
        }

        if (this.orderedAt != null) {
            isA = true
            filterCount += 1
        }

        if (this.makeAt != null) {
            isA = true
            filterCount += 1
        }


        if (this.type != null) {
            if (this.type !== ProductType.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.stage != null) {
            if (this.stage !== Stage.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.stages != null) {
            if (this.stages.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.status != null) {
            if (this.status !== Status.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.purpose != null) {
            if (this.purpose !== Purpose.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.fromStoreSection != null) {
            if (this.fromStoreSection !== FromStoreSection.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.saleType != null) {
            if (this.saleType !== SaleType.None) {
                isA = true
                filterCount += 1
            }
        }

        if (this.styleNo != null) {
            if (this.styleNo.length) {
                isA = true
                filterCount += 1
            }
        }

        if (this.challanNo != null) {
            isA = true
            filterCount += 1
        }

        if (this.trialDate != null) {
            if (this.trialDate.from != null || this.trialDate.to != null) {
                isA = true
                if (this.trialDate.from != null) {
                    filterCount += 1
                }
                if (this.trialDate.to != null) {
                    filterCount += 1
                }
            }
        }




        if (this.deliveryDate != null) {
            if (this.deliveryDate.from != null || this.deliveryDate.to != null) {
                isA = true
                if (this.deliveryDate.from != null) {
                    filterCount += 1
                }
                if (this.deliveryDate.to != null) {
                    filterCount += 1
                }
            }
        }



        if (this.retrial != null) {
            if (this.retrial.from != null || this.retrial.to != null) {
                isA = true
                if (this.retrial.from != null) {
                    filterCount += 1
                }
                if (this.retrial.to != null) {
                    filterCount += 1
                }
            }
        }




        if (this.finishing != null) {
            if (this.finishing.from != null || this.finishing.to != null) {
                isA = true
                if (this.finishing.from != null) {
                    filterCount += 1
                }
                if (this.finishing.to != null) {
                    filterCount += 1
                }
            }
        }



        //        var fromStoreSection : FromStoreSection? = null
        //        var saleType : SaleType? = null
        //        var styleNo : String? = null
        //        var byUser : User? = null
        //        var assignTo : User? = null
        //        var challan: Challan? = null


        if (this.addedByUser != null) {
            isA = true
            filterCount += 1
        }

        if (this.vendor != null) {
            isA = true
            filterCount += 1
        }

        if (this.isCancelled != null) {
            isA = true
            filterCount += 1
        }

        if (this.cancelledBy != null) {
            isA = true
            filterCount += 1
        }

        if (this.usedVendorViaFactory != null){
            if (this.usedVendorViaFactory === true){
                isA = true
                filterCount += 1
            } 
        }

        if (this.assignTo != null) {
            isA = true
            filterCount += 1
        }

        if (this.cp_assignedToInfo != null) {
            isA = true
            filterCount += 1
        }
        


        return { active: isA, filterCount: filterCount }
    }

    description() {

        if (this.active === false) {
            return ''
        }

        //        var productId : String? = null
        //        var objectId : String? = null
        //
        //        var type : ProductType? = null
        //        var stage : Stage? = null
        //        var status : Status? = null
        //        var purpose : Purpose? = null
        //        var fromStoreSection : FromStoreSection? = null
        //        var saleType : SaleType? = null
        //        var styleNo : String? = null
        //        var challan: Challan? = null
        //
        //
        //        var trialDate : DateRange = DateRange.init(from: null, to: null)
        //        var deliveryDate : DateRange = DateRange.init(from: null, to: null)
        //        var retrial : CountRange? = null
        //        var finishing : CountRange? = null



        let startState1 = "Product "
        let startState2 = "Products"
        var desc = startState1


        if (this.productId != null) {
            if (this.productId.length > 0) {
                desc += `with Id ${this.productId}`
            }
        }

        if (this.startState1 != null) {
            desc = startState2
        }

        if (this.addedByUser != null) {
            desc += ". "
            desc += `Products that are added by username ${this.addedByUser.username}`
        }

        if (this.isCancelled != null) {
            if (this.isCancelled === true) {
                desc += ". "
                desc += "Products that are cancelled"
            }
        }

        if (this.cancelledBy != null) {
            desc += ". "
            desc += `Products that are cancelled by ${this.cancelledBy.name}`
        }

        if (this.usedVendorViaFactory != null) {
            if (this.usedVendorViaFactory){
                desc += ". "
                desc += `Products which sent to vendor via factory`
            }
        }

        if (this.styleNo != null) {
            if (this.styleNo.length > 0) {
                desc += ". "
                desc += `With Style No ${this.styleNo}`
            }
        }

        if (this.type != null) {
            if (this.type !== ProductType.None) {
                desc += ". "
                desc += `Of ${this.type} type`
            }
        }

        if (this.stage != null) {
            if (this.stage !== Stage.None) {
                desc += ". "
                desc += `That are ${this.stage}`
            }
        }

        if (this.stages != null && this.stages.length) {
            desc += ". "
            let tempStr = 'That are '
            for (let i = 0; i < this.stages.length; i++) {
                const thisStage = this.stages[i];
                if (i === 0) {
                    tempStr += ' ' + thisStage
                } else {
                    tempStr += 'and ' + thisStage
                }

            }
            desc += tempStr
        }

        if (this.status != null) {
            if (this.status !== Status.None) {
                desc += ". "
                desc += `With Status ${this.status}`
            }
        }

        if (this.purpose != null) {
            if (this.purpose !== Purpose.None) {
                desc += ". "
                desc += `With Purpose ${this.purpose}`
            }
        }

        if (this.fromStoreSection != null) {
            if (this.fromStoreSection !== FromStoreSection.None) {
                desc += ". "
                desc += `From ${this.fromStoreSection} Section`
            }
        }

        if (this.saleType != null) {
            if (this.saleType !== SaleType.None) {
                desc += ". "
                desc += `With ${this.saleType} Sale`
            }
        }





        if (this.assignTo != null) {
            desc += ". "
            desc += `Assigned to username ${this.assignTo.username}`
        }

        if (this.cp_assignedToInfo != null) {
            desc += ". "
            desc += `Assigned to username ${this.cp_assignedToInfo.username}`
        }

        if (this.challanNo != null) {
            desc += ". "
            desc += `With Challan No ${this.challanNo}`
        }



        if (this.trialDate != null) {
            if (this.trialDate.from != null || this.trialDate.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                if (this.trialDate.to != null && this.trialDate.from != null) {
                    if (this.trialDate.to === this.trialDate.from) {
                        desc += `Trial on ${dateToDateString(this.trialDate.from)}`
                    } else {
                        desc += `Trial between ${dateToDateString(this.trialDate.from)}  &  ${dateToDateString(this.trialDate.to)}`
                    }
                } else {
                    if (this.trialDate.from != null) {
                        desc += `Trial after ${dateToDateString(this.trialDate.from)}`
                    }
                    if (this.trialDate.to != null) {
                        desc += `Trial before ${dateToDateString(this.trialDate.to)}`
                    }
                }
            }
        }




        if (this.deliveryDate != null) {
            if (this.deliveryDate.from != null || this.deliveryDate.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                if (this.deliveryDate.to != null && this.deliveryDate.from != null) {

                    if (this.deliveryDate.to === this.deliveryDate.from) {
                        desc += `Delivery on ${dateToDateString(this.deliveryDate.from)}`
                    } else {
                        desc += `Delivery between ${dateToDateString(this.deliveryDate.from)}  &  ${dateToDateString(this.deliveryDate.to)}`
                    }


                } else {
                    if (this.deliveryDate.from != null) {
                        desc += `Delivery after ${dateToDateString(this.deliveryDate.from)}`
                    }

                    if (this.deliveryDate.to != null) {
                        desc += `Delivery before ${dateToDateString(this.deliveryDate.to)}`
                    }

                }
            }
        }



        if (this.retrial != null) {
            if (this.retrial.from != null || this.retrial.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                desc += `Fit count ${this.retrial.from} `


                // if (this.retrial.to != null && this.retrial.from != null) {

                //     if (this.retrial.to === this.retrial.from) {
                //         desc += `${this.retrial.from} Retrials`

                //     } else {
                //         desc += `Retrials between ${this.retrial.from}  &  ${this.retrial.to}`
                //     }

                // } else {
                //     if (this.retrial.from != null) {
                //         desc += `Retrials more than or equal to ${this.retrial.from}`
                //     }

                //     if (this.retrial.to != null) {
                //         desc += `Retrials less than or equal to ${this.retrial.to}`
                //     }
                // }
            }
        }



        if (this.finishing != null) {
            if (this.finishing.from != null || this.finishing.to != null) {
                if (desc.length > 0) {
                    desc += ". With "
                }

                if (this.finishing.to != null && this.finishing.from != null) {

                    if (this.finishing.to === this.finishing.from) {
                        desc += `${this.finishing.from} Finishings`

                    } else {
                        desc += `Finishings between ${this.finishing.from}  &  ${this.finishing.to}`
                    }

                } else {
                    if (this.finishing.from != null) {
                        desc += `Finishings more than or equal to ${this.from}`
                    }

                    if (this.finishing.to != null) {
                        desc += `Finishings less than or equal to ${this.to}`
                    }
                }
            }
        }




        //TODO:- ByUser & AssignToPending

        if (desc === startState1 || desc === startState2) {
            desc = ""
        } else {
            desc += "."
        }

        var locPrefix = ""

        if (this.orderedAt != null) {
            locPrefix += `Ordered at ${this.orderedAt.name}. `
        }

        if (this.vendor != null) {
            locPrefix += `With Vendor - ${this.vendor.name}. `
        }

        if (this.makeAt != null) {
            locPrefix += `Made at ${this.makeAt.name}. `
        }

        desc = `${locPrefix}${desc}`

        return desc
    }

}


class StockFilter {
    //    var requestedQuantity : Double? = null
    constructor(
        code, company, name, priceCode, quantity, lastAdded, lastAudited, hsnCode, toBeAdded, location, hasRequestedQuantity
    ) {
        this.code = code
        this.company = company
        this.name = name
        this.priceCode = priceCode
        this.quantity = quantity
        this.lastAdded = lastAdded
        this.lastAudited = lastAudited
        this.hsnCode = hsnCode
        this.toBeAdded = toBeAdded
        this.location = location
        this.hasRequestedQuantity = hasRequestedQuantity
        this.filterCount = 0
        this.active = false
    }


    isActive() {
        let result = this.decideActiveAndCount()
        this.filterCount = result.count
        this.active = result.active
        return result.active
    }

    decideActiveAndCount() {
        var filterCount = 0
        var isA = false


        if (this.name != null) {
            if (this.name.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.code != null) {
            if (this.code.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.location != null) {
            isA = true
            filterCount += 1
        }


        if (this.hasRequestedQuantity != null) {
            isA = true
            filterCount += 1
        }


        if (this.company != null) {
            if (this.company.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.priceCode != null) {
            if (this.priceCode.length > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.hsnCode != null) {
            if (this.hsnCode.isEmpty > 0) {
                isA = true
                filterCount += 1
            }
        }

        if (this.toBeAdded != null) {
            if (this.toBeAdded === true) {
                isA = true
                filterCount += 1
            }
        }

        if (this.lastAdded != null) {
            if (this.lastAdded.from != null || this.lastAdded.to != null) {
                isA = true
                if (this.lastAdded.from != null) {
                    filterCount += 1
                }
                if (this.lastAdded.to != null) {
                    filterCount += 1
                }
            }
        }


        if (this.lastAudited != null) {
            if (this.lastAudited.from != null || this.lastAudited.to != null) {
                isA = true
                if (this.lastAudited.from != null) {
                    filterCount += 1
                }
                if (this.lastAudited.to != null) {
                    filterCount += 1
                }
            }
        }


        if (this.quantity != null) {
            if (this.quantity.from != null || this.quantity.to != null) {
                isA = true
                if (this.quantity.from != null) {
                    filterCount += 1
                }
                if (this.quantity.to != null) {
                    filterCount += 1
                }
            }
        }




        return { active: isA, filterCount: filterCount }
    }


    description() {

        //            var code : String? = null
        //            var company : String? = null
        //            var name : String? = null
        //            var priceCode : String? = null
        //            var quantity : QuantRange = QuantRange.init(from: null, to: null)
        //            var lastAudited : DateRange = DateRange.init(from: null, to: null)
        //            var hsnCode : String? = null
        //            var toBeAdded : Bool? = null



        let startState1 = "Stock "
        let startState2 = "Stocks"
        var desc = startState1

        if (this.code != null) {
            if (this.code.length > 0) {
                desc += `with Code ${this.code}`
            }
        }

        if (this.name != null) {
            if (this.name.length > 0) {
                desc += `with Name ${this.name}`
            }
        }


        if (desc === startState1) {
            desc = startState2
        }


        if (this.hasRequestedQuantity != null) {
            desc += ". "
            desc += "That are Requested"
        }


        if (this.company != null) {
            if (this.company.length > 0) {
                desc += ". "
                desc += `Of Company ${this.company}`
            }
        }

        if (this.priceCode != null) {
            if (this.priceCode.length > 0) {
                desc += ". "
                desc += `With PriceCode ${this.priceCode}`
            }
        }

        if (this.hsnCode != null) {
            if (this.hsnCode.length > 0) {
                desc += ". "
                desc += `With HSN Code ${this.hsnCode}`
            }
        }

        if (this.toBeAdded != null) {
            // only matters if set to True
            if (this.toBeAdded === true) {
                desc += ". "
                desc += "Which is marked ToBeAdded"
            }
        }



        if (this.quantity.from != null || this.quantity.to != null) {
            if (desc.length > 0) {
                desc += ". With Quantity"
            }

            if (this.quantity.to != null && this.quantity.from != null) {

                if (this.quantity.to === this.quantity.from) {
                    desc += ` ${this.quantity.from}`
                } else {
                    desc += ` between ${this.quantity.from}  &  ${this.quantity.to}`
                }
            } else {
                if (this.quantity.from != null) {
                    desc += ` more than of equal to ${this.quantity.from}`
                }

                if (this.quantity.to != null) {
                    desc += ` less than of equal to ${this.quantity.to}`
                }

            }

        }


        if (this.lastAdded.from != null || this.lastAdded.to != null) {
            if (desc.length > 0) {
                desc += ". Last Added"
            }

            if (this.lastAdded.to != null && this.lastAdded.from != null) {

                if (this.lastAdded.to === this.lastAdded.from) {
                    desc += ` on ${dateToDateString(this.lastAdded.from)}`
                } else {
                    desc += ` between ${dateToDateString(this.lastAdded.from)}  &  ${dateToDateString(this.lastAdded.to)}`
                }


            } else {
                if (this.lastAdded.from != null) {
                    desc += ` after ${dateToDateString(this.lastAdded.from)}`
                }

                if (this.lastAdded.to != null) {
                    desc += ` before ${dateToDateString(this.lastAdded.to)}`
                }

            }

        }



        if (this.lastAudited.from != null || this.lastAudited.to != null) {
            if (desc.length > 0) {
                desc += ". Last Audited"
            }

            if (this.lastAudited.to != null && this.lastAudited.from != null) {

                if (this.lastAudited.to === this.lastAudited.from) {
                    desc += ` on ${dateToDateString(this.lastAudited.from)}`
                } else {
                    desc += ` between ${dateToDateString(this.lastAudited.from)}  &  ${dateToDateString(this.lastAudited.to)}`
                }


            } else {
                if (this.lastAudited.from != null) {
                    desc += ` after ${dateToDateString(this.lastAudited.from)}`
                }

                if (this.lastAudited.to != null) {
                    desc += ` before ${dateToDateString(this.lastAudited.to)}`
                }

            }

        }


        if (desc === startState1 || desc === startState2) {
            desc = ""
        } else {
            desc += "."
        }

        var locPrefix = ""

        if (this.location != null) {
            locPrefix += `Stocks at ${this.location}. `
        }

        desc = `${locPrefix} ${desc}`


        return desc
    }


}

